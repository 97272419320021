import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { styled } from '@mui/material/styles';


const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    width: "auto",
    fontSize: 14,
    textAlign: "justify",
    backgroundColor: "rgb(249 249 249)",
    padding: 10,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    color: "rgb(0 0 0)",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
      fontSize: 14,
      padding: 8,
    },
  },
  [`&.${tooltipClasses.popper}[data-popper-placement="top"]`]: {
    transform: "translateY(-5px)",
  },
}));

export const ToolTipSearch = (props) => {
    return (
      <CustomWidthTooltip title={props.title} placement="right">
        {props.children}
      </CustomWidthTooltip>
    );
}
